$media: screen;
$feature_min: min-width;
$feature_max: max-width;
$value_one: 767px;
$value_two: 768px;
$value_three: 991px;
$value_four: 992px;
$value_five: 1199px;
$value_six: 1400px;
$template_color: #6d1e37;
@media only #{$media} and ($feature_max: $value_one) {
    .ptb-80 {
        padding-bottom: 50px;
        padding-top: 50px;
    }
    p {
        font-size: 14px;
    }
    img {
        width: 100%;
    }
    .btn {
        font-size: 14px;
    }
    .owl-theme {
        .owl-nav {
            [class*="owl-"] {
                top: auto;
                bottom: 0;
                left: 0;
            }
            .owl-next {
                left: auto;
                right: 0;
            }
        }
    }
    .section-title {
        margin-bottom: 40px;
        h4 {
            font-size: 16px;
            margin-bottom: 15px;
        }
        h2 {
            font-size: 28px;
        }
        p {
            font-size: 14px;
        }
    }
    .navbar {
        position: relative;
        padding: {
            top: 5px;
            bottom: 5px;
        }
        background: transparent !important;
        &.navbar-light {
            .navbar-brand {
                color: #000000;
                padding-top: 0;
                &:focus,
                &:hover {
                    color: #000000;
                }
            }
            .navbar-toggler {
                border-color: $template_color;
                .navbar-toggler-icon {
                    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(109, 30, 55, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
                }
            }
            .navbar-nav {
                .nav-link {
                    color: #000000;
                }
            }
        }
    }
    .main-banner {
        height: 100vh;
        padding-top: 110px;
        padding-bottom: 110px;
    }
    .creative-banner-one {
        border-width: 5rem 100vw 0 0;
    }
    .creative-banner-two {
        &::before {
            border-top: 90px solid transparent;
        }
        &::after {
            border-top: 90px solid transparent;
        }
    }
    .creative-banner-three {
        &::before {
            height: 40px;
            background-size: cover;
            bottom: 0;
        }
    }
    .main-banner-text {
        h4 {
            font-size: 20px;
            letter-spacing: 0;
        }
        h1 {
            font-size: 35px;
            margin: {
                top: 15px;
                bottom: 15px;
            }
            letter-spacing: 0;
            line-height: 45px;
        }
        p {
            font-size: 15px;
        }
        .view-work {
            margin-left: 0;
        }
    }
    .welcome-area {
        padding-bottom: 20px;
        .section-title {
            h2 {
                line-height: 1.3;
                margin-top: -3px;
            }
        }
    }
    .single-box {
        margin-bottom: 30px;
        .icon {
            font-size: 35px;
        }
        h3 {
            margin: {
                top: 20px;
                bottom: 18px;
            }
            font-size: 18px;
        }
    }
    .about-addax {
        .section-title {
            h2 {
                line-height: 1.4;
                margin-top: -10px;
                font-size: 18px;
            }
        }
        .pull-left {
            margin-right: 0;
            float: none;
            margin-bottom: 10px;
        }
        ul {
            li {
                font-size: 14px;
            }
        }
    }
    .about-video {
        margin-top: 30px;
        .video-btn {
            a {
                width: 60px;
                height: 60px;
                line-height: 65px;
                font-size: 25px;
                &::before,
                &::after {
                    height: 70px;
                    width: 70px;
                }
            }
        }
    }
    .strategy-area {
        .image {
            display: none;
        }
    }
    .about-strategy {
        padding-left: 0;
        ul {
            li {
                font-size: 14px;
            }
        }
    }
    .who-we-are {
        padding-bottom: 20px;
    }
    .single-who-we-are {
        padding: 35px 20px;
        i {
            font-size: 35px;
        }
        h4 {
            font-size: 18px;
        }
    }
    .ctr-area {
        .section-title {
            h2 {
                margin: {
                    top: 20px;
                    bottom: 20px;
                }
                line-height: 1.3;
            }
            p {
                margin-bottom: 25px;
            }
        }
    }
    .about-skill {
        .section-title {
            padding-left: 30px;
            &::before,
            &::after {
                display: block;
            }
            h2 {
                font-size: 29px;
            }
        }
        .progress-title {
            font-size: 15px;
            margin-bottom: 10px;
        }
        .progress {
            height: 8px;
            margin-bottom: 20px;
            .progress-value {
                font-size: 14px;
            }
        }
    }
    .skill-video {
        margin-top: 30px;
        .video-btn {
            a {
                width: 60px;
                height: 60px;
                line-height: 65px;
                font-size: 25px;
                &::before,
                &::after {
                    height: 70px;
                    width: 70px;
                }
            }
        }
    }
    .funFacts-area {
        padding-bottom: 20px;
    }
    .funFact {
        margin-bottom: 30px;
        padding-left: 50px;
        .fa.fa-users {
            left: 0;
            font-size: 35px;
            top: 5px;
        }
        i {
            font-size: 35px
        }
        h2 {
            font-size: 30px;
        }
        p {
            font-size: 16px;
        }
    }
    .team-area {
        .section-title {
            h2 {
                line-height: 1.3;
                margin-top: -4px;
            }
        }
    }
    .our-team {
        .team-content {
            .title {
                font-size: 18px;
                margin-bottom: 10px;
            }
            .post {
                font-size: 14px;
            }
        }
    }
    .how-work {
        padding-bottom: 20px;
    }
    .single-how-work {
        padding: 30px;
        margin-bottom: 30px;
        h3 {
            font-size: 35px;
        }
        h4 {
            margin: {
                top: 20px;
                bottom: 15px;
            }
            font-size: 18px;
        }
    }
    .services-area {
        padding-bottom: 20px;
    }
    .single-services {
        .services-content {
            padding: 30px 20px;
            h3 {
                font-size: 18px;
                margin-bottom: 15px;
            }
        }
    }
    .why-we-different {
        .section-title {
            h2 {
                line-height: 1.3;
                display: inline-block;
                margin-top: -5px;
            }
        }
    }
    .tabset {
        label {
            display: block;
            padding: 14px 20px;
            margin: {
                right: 0;
                bottom: 5px;
            }
            border-radius: 5px;
            font-size: 16px;
        }
    }
    .tab-panel {
        .why-we-different-text {
            h4 {
                font: {
                    size: 20px;
                    weight: 500;
                }
                margin-bottom: 20px;
            }
            ul {
                margin-bottom: 25px;
                li {
                    font-size: 14px;
                }
            }
        }
        .why-we-different-img {
            margin-top: 35px;
        }
    }
    .shorting-menu {
        display: block;
        box-shadow: unset;
        padding: 0;
        text-align: center;
        border-radius: 0;
        .filter {
            padding: 8px 22px;
            font-size: 14px;
            margin-bottom: 7px;
        }
    }
    .shorting {
        margin-top: 25px;
        .single-work {
            padding: 0;
            margin: 0;
            &:hover::before {
                top: 0;
                width: 100%;
                height: 100%;
                left: 0;
            }
            .work-content {
                bottom: -95px;
                width: 100%;
                left: 0;
                padding: {
                    top: 20px;
                    bottom: 20px;
                }
                h4 {
                    font-size: 18px;
                }
            }
            .popup-btn {
                top: 5px;
                right: 15px;
            }
            &:hover {
                .work-content {
                    bottom: 0;
                }
                .popup-btn {
                    top: 15px;
                }
            }
        }
    }
    .price-area {
        padding-bottom: 20px;
        .section-title {
            h2 {
                line-height: 1.3;
                display: inline-block;
                margin-top: -5px;
            }
        }
    }
    .pricingTable {
        margin-bottom: 30px;
        padding: 20px 0;
        .title {
            padding: 18px 0;
            margin-bottom: 30px;
            font: {
                size: 18px;
                weight: 500;
            }
        }
        .price-value {
            .currency {
                font-size: 18px;
            }
            .amount {
                font-size: 30px;
            }
            .month {
                font-size: 14px;
                line-height: 15px;
                margin-top: 5px;
            }
        }
        &::after {
            width: 55px;
            height: 20px;
            top: 73px;
        }
        .pricing-content {
            border: {
                top: 1px solid #eeeeee;
                bottom: 1px solid #eeeeee;
            }
            margin-bottom: 20px;
            padding: {
                top: 20px;
                bottom: 20px;
            }
            li {
                margin-top: 14px;
                font-size: 14px;
            }
        }
    }
    .faq-area {
        .section-title {
            h2 {
                line-height: 1.3;
                display: inline-block;
                margin-top: -5px;
            }
        }
        .image {
            display: none;
        }
    }
    .card {
        .card-header {
            a {
                h5 {
                    font-size: 15px;
                    padding: 10px;
                    line-height: 25px;
                }
            }
            i {
                display: none;
            }
        }
        .card-body {
            font-size: 14px;
        }
    }
    .testimonials-area {
        padding-bottom: 20px;
        .section-title {
            h2 {
                display: inline-block;
                line-height: 1.3;
                margin-top: -5px;
            }
        }
    }
    .single-feedback {
        padding: 20px;
        .client-info {
            margin-bottom: 30px;
            position: relative;
            overflow: unset;
            padding-left: 95px;
            .client-pic {
                float: unset;
                position: absolute;
                left: 0;
                top: -13px;
            }
            .client-title {
                float: unset;
                width: 100%;
                padding-left: 0;
                margin-top: 15px;
                h4 {
                    font-size: 18px;
                }
                h5 {
                    font-size: 14px;
                    margin-top: 10px;
                }
            }
        }
        span {
            font-size: 100px;
            right: 15px;
        }
        p {
            margin-top: 40px;
        }
    }
    .single-blog-item {
        span {
            font-size: 14px;
        }
        h4 {
            font-size: 18px;
        }
    }
    .subscribe-area {
        text-align: left;
    }
    .newsletter {
        padding: 30px 20px;
        text-align: center;
        h4 {
            margin-bottom: 20px;
            font-size: 22px;
        }
        form {
            .form-control {
                height: 60px;
                border-radius: 30px;
                padding-left: 15px;
                font-size: 14px;
            }
            .btn {
                position: unset;
                right: 0;
                top: 0;
                height: 50px;
                width: auto;
                margin-top: 20px;
            }
        }
    }
    .contact-info {
        padding: 25px 25px 30px;
        ul {
            li {
                font-size: 14px;
                &:first-child {
                    i {
                        top: 0;
                    }
                }
            }
        }
    }
    .contact-form {
        padding: 40px 25px;
        margin-top: 30px;
        label {
            font-size: 14px;
        }
    }
    .page-title {
        height: 100%;
        padding: {
            top: 50px;
            bottom: 50px;
        }
        h1 {
            margin: {
                bottom: 15px;
                top: 0;
            }
            font-size: 24px;
        }
        ul {
            li {
                font-size: 14px;
                text-transform: capitalize;
                a {
                    font-size: 14px;
                }
            }
        }
    }
    .blog-details-area {
        .title {
            margin: {
                top: 25px;
                bottom: 15px;
            }
            font-size: 20px;
            line-height: 1.4;
        }
    }
    .blog-details {
        .article-img {
            .date {
                bottom: 5px;
                width: 70px;
                left: 5px;
                height: 70px;
                font-size: 18px;
                padding-top: 10px;
                line-height: 25px;
            }
        }
        .article-text {
            padding: 25px 15px;
            ul {
                li {
                    margin-bottom: 5px;
                }
            }
            .category {
                a {
                    font-size: 14px;
                }
            }
            .blockquote {
                padding: 15px;
                font-size: 15px;
                border-left: 3px solid $template_color;
            }
            .author-share {
                .article-author {
                    overflow: unset;
                    position: relative;
                    padding-left: 105px;
                    .author-img {
                        float: unset;
                        width: 100%;
                        position: absolute;
                        left: 0;
                        top: -25px;
                    }
                    .author-title {
                        margin-top: 15px;
                        float: unset;
                        width: 100%;
                        padding: 0;
                        h4 {
                            font-size: 17px;
                        }
                        span {
                            font-size: 14px;
                        }
                    }
                }
                .social-share {
                    text-align: center;
                    margin-top: 35px;
                    a {
                        width: 30px;
                        height: 30px;
                        line-height: 30px;
                        font-size: 14px;
                    }
                }
            }
        }
    }
    .post-controls-buttons {
        padding: {
            left: 0;
            right: 0;
        }
    }
    .post-comments {
        .single-comment {
            padding: 20px 15px 20px 110px;
            margin-bottom: 20px;
            overflow: unset;
            position: relative;
            .comment-img {
                float: unset;
                width: auto;
                position: absolute;
                left: 10px;
                top: 20px;
            }
            .comment-content {
                float: unset;
                width: 100%;
                padding-left: 0;
                h4 {
                    font-size: 17px;
                    text-transform: uppercase;
                    margin-bottom: 15px;
                }
                span {
                    font-size: 14px;
                }
                a {
                    right: auto;
                    bottom: 20px;
                    font-size: 16px;
                    left: 15px;
                }
            }
        }
    }
    .leave-a-reply {
        padding: 20px 15px;
    }
    .footer-area {
        padding: 35px 0;
        h4 {
            line-height: 20px;
            margin-bottom: 25px;
            a {
                font-size: 22px;
            }
        }
        p {
            font-size: 14px;
        }
        ul {
            li {
                a {
                    width: 30px;
                    height: 30px;
                    line-height: 30px;
                    font-size: 14px;
                }
            }
        }
    }
}

@media only #{$media} and ($feature_min: $value_two) and ($feature_max: $value_three) {
    .owl-theme {
        .owl-nav {
            [class*="owl-"] {
                left: 0;
                top: auto;
                bottom: 20px;
            }
            .owl-next {
                left: auto;
                right: 0;
            }
        }
    }
    p {
        font-size: 15px;
    }
    .navbar {
        position: relative;
        padding: {
            top: 5px;
            bottom: 5px;
        }
        background: transparent !important;
        &.navbar-light {
            .navbar-brand {
                color: #000000;
            }
            &:focus,
            &:hover {
                color: #000000;
            }
            .navbar-toggler {
                border-color: $template_color;
                .navbar-toggler-icon {
                    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 44, 87, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
                }
            }
            .navbar-nav {
                .nav-link {
                    color: #000000;
                }
            }
        }
    }
    .main-banner {
        height: 100vh;
        padding-top: 120px;
        padding-bottom: 120px;
    }
    .creative-banner-two {
        &::before {
            border-top: 100px solid transparent;
        }
        &::after {
            border-top: 100px solid transparent;
        }
    }
    .creative-banner-three {
        &::before {
            height: 60px;
            background-size: cover;
            bottom: 0;
        }
    }
    .main-banner-text {
        h4 {
            font-size: 18px;
        }
        h1 {
            font-size: 45px;
            margin-bottom: 25px;
        }
    }
    .welcome-area {
        padding-bottom: 50px;
    }
    .single-box {
        margin-bottom: 30px;
    }
    .about-addax {
        .section-title {
            h2 {
                font-size: 22px;
            }
        }
        ul {
            li {
                font-size: 15px;
            }
        }
    }
    .about-strategy {
        ul {
            li {
                font-size: 15px;
            }
        }
    }
    .about-video {
        margin-top: 40px;
        img {
            width: 100%;
        }
    }
    .skill-video {
        margin-top: 40px;
        img {
            width: 100%;
        }
    }
    .funFacts-area {
        padding-bottom: 50px;
    }
    .funFact {
        margin-bottom: 30px;
    }
    .how-work {
        padding-bottom: 50px;
    }
    .single-how-work {
        margin-bottom: 30px;
    }
    .tabset {
        label {
            padding: 14px 15px;
            margin-right: 2px;
            font-size: 16px;
        }
    }
    .why-we-different-img {
        margin-top: 58px;
    }
    .shorting-menu {
        display: block;
        padding: 15px 15px;
    }
    .single-work {
        padding: 0;
        margin: 0;
        &:hover::before {
            top: 0;
            width: 100%;
            height: 100%;
            left: 0;
        }
        .work-content {
            bottom: 0;
            width: 100%;
            left: 0;
        }
        &:hover {
            .work-content {
                bottom: 10px;
            }
        }
    }
    .price-area {
        padding-bottom: 50px;
    }
    .pricingTable {
        margin-bottom: 30px;
    }
    .faq-area {
        .image {
            display: none;
        }
    }
    .contact-form {
        margin-top: 40px;
    }
    .page-title {
        height: 430px;
        h1 {
            margin-top: 20px;
            margin-bottom: 25px;
        }
    }
}

@media only #{$media} and ($feature_min: $value_four) and ($feature_max: $value_five) {
    .about-addax {
        .pull-left {
            margin-right: 35px;
        }
    }
    .single-who-we-are {
        padding: 35px 30px;
    }
    .about-skill {
        .section-title {
            margin-bottom: 40px;
        }
    }
    .skill-video {
        margin-top: 60px;
    }
    .single-feedback {
        padding: 30px 25px;
        .client-title {
            h5 {
                margin-top: 9px;
            }
        }
    }
    #map {
        height: 383px;
    }
}

@media only #{$media} and ($feature_min: $value_six) {
    .main-banner {
        height: 100vh;
    }
}